.container-fluid.root {
  padding: 0;
}

.root-jumbotron {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 56px - 56px);
  @include with-dark-flexible-background();

  margin: 50px 0 0 0;
  padding: 0;
  border-radius: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .root-jumbotron-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .search-form {
      display: inline-block;
      text-align: center;

      @include media-breakpoint-up(sm) {
        width: 28rem;
      }

      .form-group:last-of-type {
        margin-bottom: 0;
      }
    }

    .amazon-link {
      width: 100%;

      &.btn.btn-light {
        color: #495057;
      }

      .description, .associate-notification {
        white-space: nowrap;
      }

      .associate-notification {
        font-size: 75%;
      }
    }
  }
}

.root-box {
  max-height: 100%;
  margin: 1rem;
  padding: 2rem 1rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;

  .h4, .h5 {
    margin-bottom: 1rem;
  }

  > * {
    margin-bottom: 1rem;

  }

  .logo-container {
    line-height: 1;
    font-size: 1.5rem;

    img {
      width: 12rem;
    }
  }

  .share-this-site {
    margin-bottom: 0;

    :last-child {
      margin-bottom: 0;
    }
  }

  .root-box-footer {
    margin-bottom: 0;

    @extend .flat;
    .icon {
      text-align: center;
      margin-right: 0.25em;
    }
  }

  @include media-breakpoint-up(md) {
    .root-box-footer {
      display: none;
    }
  }

  @include media-breakpoint-only(xs) {
    .logo-container {
      display: none;
    }
  }

  @media (max-width: 350px) {
    .share-this-site {
      ul.flat li {
        margin-right: 0.25em;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-height: 550px) {
    padding: 1rem;

    > * {
      margin-bottom: 1rem;
    }

    .search-form {
      margin-top: 0;
    }

    .h4, .h5 {
      margin-bottom: 0.5rem;
    }
  }
}
