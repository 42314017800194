$vivid-orange: #f3470e;

$blue: #470ff2;
$indigo: #800ff2;
$purple: #930ff2;
$pink: #f20ff2;
$red: #f20f0f;
$orange: #f2800f;
$yellow: #f2f20f;
$green: #0ccc3c;
$teal: #0ff280;
$cyan: #27c2f2;

$secondary: $gray-300;

$font-family-sans-serif:
  "-apple-system",
  "BlinkMacSystemFont",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  "ヒラギノ角ゴ ProN",
  "Hiragino Kaku Gothic ProN",
  "メイリオ",
  "Meiryo",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji"
  !default;

$font-family-monospace:
  "SFMono-Regular",
  "Menlo",
  "Monaco",
  "Consolas",
  "Liberation Mono",
  "Courier New",
  monospace
  !default;

$font-family-serif:
  "Times New Roman",
  "Times",
  "Yu Mincho",
  "YuMincho",
  "ヒラギノ明朝 ProN",
  "Hiragino Mincho ProN",
  "ＭＳ Ｐ明朝",
  "MS PMincho",
  "MS 明朝",
  serif !default;

$spacer: 1rem !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * .875 !default;

$headings-margin-bottom: $spacer !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base * 1.1 !default;
$h5-font-size: $font-size-base * 1.0 !default;
$h6-font-size: $font-size-base !default;

$headings-color: $body-color;

$primary: $vivid-orange;
$dropdown-link-hover-bg: $vivid-orange;

$code-color: inherit;
