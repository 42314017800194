.search-form {
  .input-group-append {
    .icon {
      text-align: center;
      width: calc(20em / 16);
    }
  }

  .value-presented {
    .btn {
      color: $primary;
    }
  }

  .option-dropdown-has-movie {
    .icon {
      margin-right: 0;
    }
  }

  .option-dropdown-with-hierarchy {
    @include media-breakpoint-up(sm) {
      display: none;
    }

    .option-title {
      display: inline-block;
      width: 4.5em;
    }

    .option-value {
      font-size: 75%;
    }
  }

  .option-dropdown-flat {
    @include media-breakpoint-only(xs) {
      display: none;
    }
  }
}

.root-box {
  .search-form {
    .option-dropdown-flat {
      .icon {
        margin-right: 0;
      }

      .option-value {
        display: none;
      }
    }
  }
}
