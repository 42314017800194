//
// Basic design
//

#app {
  margin-top: 56px;
  margin-bottom: 56px;

  .container-fluid:not(.root) {
    margin-bottom: 72px;
  }
}

.modal-dialog {
  margin-top: 60px;
}

.modal-body {
  &, div {
    *:last-child {
      margin-bottom: 0;
    }
  }
}

.badge {
  position: relative;
  bottom: 0.1em;
}

em {
  font-style: normal;
  font-weight: bolder;
}

strong {
  font-weight: bolder;
  text-decoration: underline;
}

.dropdown-item-title {
  display: inline-block;
  padding-right: 18px;
}

.dropdown-item-check {
  width: 15px;
  float: right;
}

.page-header {
  h1 {
    .title {
      margin-right: 0.5em;
    }

    .subtitle {
      @include media-breakpoint-only(xs) {
        font-size: 50%;
      }
    }
  }
}

//
// Page-top container (includes page-header and extra container)
//

.page-top-container {
  @include with-dark-flexible-background;
  background-position: top center;
  margin: 0 -15px 1rem -15px;
  padding: 0;

  .page-header {
    margin: 0;
    padding: 0;
    border-bottom: none;

    h1 {
      margin: 0;
    }
  }

  .extra {
    margin: 1rem 0 0 0;
    padding-top: 1rem;
    border-top: rgba(255, 255, 255, 0.8) solid 1px;
    .form-group {
      margin-bottom: 0;
    }
  }
}

.page-top-payload {
  padding: 1rem 15px 1rem 15px;
  background-color: rgba(0, 0, 0, 0.8);
}

//
// Pagination
//

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .pagination-info, .pagination {
    margin-bottom: 0;
    white-space: nowrap;
  }

  margin-bottom: 1rem;
}

.pagination {
  .page-item.page {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

//
// Icons
//

.icon {
  display: inline-block;
}

.icon-spin {
  animation: icon-spin 1.5s linear infinite;
}

@keyframes icon-spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.with-icon {
  .icon {
    margin-right: 0.25em;

    &.icon-salt {
      margin-right: 0;
      position: relative;
      bottom: 0.1em;
    }
  }
}
