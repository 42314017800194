ul.plain {
  list-style-type: none;
  padding-left: 0;
}
ul.flat {
  list-style-type: none;
  padding-left: 0;

  & > li {
    display: inline-block;
    margin-right: 0.5em;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0;
    }
  }


  &.flat.with-separator  {
    & > li {
      margin-right: 0;

      &:not(:last-child):after {
        margin-left: 8px;
        margin-right: 8px;
        content: "\B7";
        opacity: 0.2;
      }
    }
  }
}

.centering {
  text-align: center;
}

.small {
  font-size: 75%;
}

.qrcode {
  image-rendering: pixelated;
}
