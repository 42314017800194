// Cerulean 4.3.1
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #033C73 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #C71C22 !default;
$orange:  #fd7e14 !default;
$yellow:  #DD5600 !default;
$green:   #73A839 !default;
$teal:    #20c997 !default;
$cyan:    #2FA4E7 !default;

$primary:       $cyan !default;
$secondary:     $gray-200 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// Body

$body-color:    $gray-700 !default;

// Fonts

$headings-color:         $cyan !default;

// Dropdowns

$dropdown-link-color:            $body-color !default;
$dropdown-link-hover-color:      $white !default;
$dropdown-link-hover-bg:         $primary !default;

// Navbar

$navbar-dark-color:                 rgba($white,.8) !default;
$navbar-dark-hover-color:           $white !default;
