.recipe-index-adsense-top {
  margin: 1rem 0;
}

.recipe-index-adsense-bottom {
  margin: 0;
}

.recipe-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-right: -0.5rem;
  margin-bottom: -8px;
  justify-content: flex-start;

  .recipe-card, .adsense-card, .amazon-product-card {
    @include media-breakpoint-only(xs) {
      flex-basis: calc(100% / 1 - 0.5rem);
    }
    @include media-breakpoint-only(sm) {
      flex-basis: calc(100% / 2 - 0.5rem);
    }
    @include media-breakpoint-between(md, lg) {
      flex-basis: calc(100% / 3 - 0.5rem);
    }
    @include media-breakpoint-only(xl) {
      flex-basis: calc(100% / 4 - 0.5rem);
    }

    flex-shrink: 0;
    margin: 0 0.5rem 0.5rem 0;
    overflow: hidden;

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 20px;

      & > *, & > * > * {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .card-title {
      font-weight: bolder;
      margin-bottom: 1rem;

      a {
        color: $body-color;
        display: block;
      }
    }

    .ingredients, .properties {
      list-style-type: none;
      padding-left: 0;

      & > li {
        margin-right: 0.5em;
        display: inline;

        &:last-of-type {
          margin-right: 0;
        }

        .badge {
          font-weight: normal;
        }
      }
    }

    .site-badge {
      display: inline-block;
      vertical-align: middle;
      height: 26px;
      font-weight: normal;

      padding-left: 1em;
      padding-right: 1em;

      img {
        margin-right: 0.5em;
        border-radius: 0.125rem;
      }

      .site-name {
        position: relative;
        bottom: -0.1em;
      }
    }
  }

  .recipe-card {
    .card-img, .card-img-top, .card-img-bottom {
      background-image: url("../images/loading@inline.svg");
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center center;

      width: 100%;
      padding-top: 52.356%;
      position: relative;

      & > picture {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 100%;
        }
      }
    }

  }

  .amazon-product-card {
    .card-img, .card-img-top, .card-img-bottom {
      & > picture {
        img {
          width: 100%;
        }
      }
    }
  }
}

.has-movie-icon::before {
  content: "\25b6";

  font-size: 60%;
  color: $body-bg;
  background-color: $body-color;

  padding: 0.1em 0.5em;
  border-radius: 0.2em;

  position: relative;
  bottom: 0.2em;
}

.copyright-description {
  *[lang="en"] {
    font-size: 75%;
  }
}

h1 {
  .subtitle {
    ul.flat {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}
