#notification-container {
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 100%;

  margin: 0;
  padding: 4px 15px;
  z-index: 2000;

  .alert {
    margin-top: 0px;
    margin-bottom: 4px;
    opacity: 0.9;

    @include media-breakpoint-only(xs) {
      border-radius: 0px;
    }
  }
}

#processing-notification {
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  width: 12rem;
  margin: auto;

  .badge {
    width: 16px;
    float: right;
    position: relative;
    top: 3px;
  }
}
