$navbar-light-hover-bg-color: rgba(255, 255, 255, 0.1);
$navbar-light-hover-border-color: $vivid-orange;

.navbar.header, .navbar.footer {
  .icon {
    text-align: center;
    width: calc(20em / 16);
  }
}

.navbar-brand {
  padding: 0;
}

.page-header {
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba($black, .125);
}

#brand-title {
  margin-left: 8px;
}

#beta-badge {
  margin-left: 8px;
}

.navbar.header {
  height: 56px;
  padding-top: 0;
  padding-bottom: 0;

  .navbar-brand {
    height: 54px;
    margin: 0 0 0 -0.5rem;
    padding: 10px 0.5rem;

    @media (hover: hover) {
      &:hover {
        background-color: $navbar-light-hover-bg-color;
      }
    }
  }

  .navbar-nav {
    margin-right: -0.5rem;
    flex-direction: row;
  }

  .nav-link {
    padding: 16px 8px;

    @media (hover: hover) {
      &:hover {
        background-color: $navbar-light-hover-bg-color;
        border-bottom: $navbar-light-hover-border-color solid 4px;
        padding-bottom: 12px;
      }
    }

    .icon {
      width: auto;
    }

    .icon-name {
      display: inline-block;
      margin-left: 0.25rem;

      &.only-on-dropdowns {
        display: none;
      }
    }
  }

  .dropdown {
    margin-bottom: -0.5rem;

    .dropdown-toggle {
      margin-bottom: 0.5rem;
    }
  }
}


.navbar.footer {
  height: 56px;
  padding-top: 0;
  padding-bottom: 0;

  .nav-link {
    padding: 16px 8px;

    @media (hover: hover) {
      &:hover {
        background-color: $navbar-light-hover-bg-color;
        border-top: $navbar-light-hover-border-color solid 4px;
        padding-top: 12px;
      }
    }

    .icon {
      width: auto;
    }

    .icon-name {
      display: inline-block;
      margin-left: 0.25rem;

      &.only-on-dropdowns {
        display: none;
      }
    }
  }

  .navbar-nav {
    flex-direction: row;
  }

  .left {
    margin-left: -0.5rem;
  }

  .right {
    margin-right: -0.5rem;
  }
}
