@import "cerulean/variables";
@import "bootstrap.variables";
@import "~bootstrap/scss/bootstrap";
@import "cerulean/bootswatch";

.bg-dark {
  @include btn-shadow(darken($vivid-orange, 45%));
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

code {
  font-size: inherit;
  background-color: rgba(127, 127, 127, 0.1);
}

pre {
  white-space: pre-wrap;
}
