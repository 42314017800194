.share-this-site {
  ul {
    font-size: 200%;
  }

  .share-list {
    margin-top: -1rem;
    margin-bottom: 0.5rem;

    .help {
      color: lighten($info, 60%);
    }
  }
}

.in-navbar .share-this-site {
  a, a:link, a:visited, a:hover, a:active, a:focus {
    color: $body-color;
  }

  .share-list, .qrcode-container {
    text-align: center;
  }
}
