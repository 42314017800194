// Cerulean 4.3.1
// Bootswatch


@mixin btn-shadow($color){
  @include gradient-y-three-colors(lighten($color, 8%), $color, 60%, darken($color, 4%));
}

$text-shadow: 0 1px 0 rgba(0, 0, 0, 0.05) !default;

// Navbar ======================================================================

.bg-primary {
  @include btn-shadow($primary);
}

.bg-dark {
  @include btn-shadow($blue);
}

.bg-light {
  @include gradient-y-three-colors(lighten($gray-200, 8%), $gray-200, 60%, darken($gray-200, 2%));
}

.navbar-brand,
.nav-link {
  text-shadow: $text-shadow
}

// Buttons =====================================================================

.btn {
  text-shadow: $text-shadow
}

.btn-primary {
  @include btn-shadow($primary);
}

.btn-secondary {
  @include btn-shadow($secondary);
  color: $gray-700;
}

.btn-success {
  @include btn-shadow($success);
}

.btn-info {
  @include btn-shadow($info);
}

.btn-warning {
  @include btn-shadow($warning);
}

.btn-danger {
  @include btn-shadow($danger);
}

.btn-light {
  @include btn-shadow($light);
}

.btn-dark {
  @include btn-shadow($dark);
}


// Typography ==================================================================

.text-secondary {
  color: $gray-500 !important;
}

.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-dark {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}

// Tables ======================================================================

// Forms =======================================================================

// Navs ========================================================================

.dropdown-menu {
  .dropdown-header {
    color: $gray-600;
  }
}

// Indicators ==================================================================

// Progress bars ===============================================================

// Containers ==================================================================
