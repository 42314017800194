@mixin with-dark-flexible-background($color: #ffffff) {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  $max-size: 1280;
  $sizes: 1152, 1024, 896, 640, 512, 384;

  background-image: url("../images/background/background@#{$max-size}x#{$max-size}.webp");
  .webp-unsupported & {
    background-image: url("../images/background/background@#{$max-size}x#{$max-size}.jpg");
  }

  @each $size in $sizes {
    @media (max-width: #{$size}px) {
      background-image: url("../images/background/background@#{$size}x#{$size}.webp")
    }
    .webp-unsupported & {
      @media (max-width: #{$size}px) {
        background-image: url("../images/background/background@#{$size}x#{$size}.jpg")
      }
    }
  }

  a:link, a:visited, a:hover, a:active, a:focus, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $color;
  }
}
